<template>
 <div>
   <b-row>
     <b-col>
       <b-card>
         <b-row>
           <b-col>
             <h4>Search Contacts</h4>
           </b-col>
           <b-col class="text-right">
             <b-button variant="primary" size="sm" squared @click="addContact">Add Contact</b-button>
           </b-col>
         </b-row>
         <b-row>
           <b-col cols="4">
             <label>Name</label>
             <b-form-input v-model="contact.name"></b-form-input>
           </b-col>
           <b-col cols="4">
             <label>Surname</label>
             <b-form-input v-model="contact.surname"></b-form-input>
           </b-col>
           <b-col cols="4">
             <label>Email</label>
             <b-form-input v-model="contact.email"></b-form-input>
           </b-col>
         </b-row>
         <hr class="mx-3">
         <b-row>
           <b-col class="text-right">
            
             <b-button variant="red" class="ml-2" squared @click="clearSearch" >Cancel</b-button>
             <b-button variant="primary" class="ml-2" squared @click="searchResults" >Search</b-button>
           </b-col>
         </b-row>

       </b-card>
     </b-col>
   </b-row>

   <b-row class="mt-3">
     <b-col>
       <b-card>
         <b-row>
           <b-col>
             <div class="d-flex justify-content-end">
               <download-csv
                   :data   = "jsonData"
                   :name    = fileName>

                 <b-button variant="primary" squared class="text-right" @click="downloadMessage">Export to .xls</b-button>

               </download-csv>
             </div>
             <b-table striped hover 
                      :items="tableData.dataSource" 
                      :fields="tableData.tableColumns"
                      :busy="tableData.isLoading" 
                      @row-clicked="openContact" 
                      :per-page="tableData.resultsPerPage" 
                      id="contactTable" 
                      :current-page="tableData.currentPage" sort-icon-left>

               <template #table-busy>
                 <div class="text-center my-2">
                   <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                 </div>
               </template>

               <template #cell(actions)="row">
                 <b-row align-v="center" align-h="end">
                   <b-button @click="openContact(row.item)" size="sm" class="btn-icon">
                     <b-icon-chevron-right></b-icon-chevron-right>
                   </b-button>
                 </b-row>
               </template>
             </b-table>
           </b-col>
         </b-row>
         <b-row align-h="center" >
           <b-pagination
               v-model="tableData.currentPage"
               :total-rows="rows"
               :per-page="tableData.resultsPerPage"
               aria-controls="contactTable"
           ></b-pagination>
         </b-row>
       </b-card>
     </b-col>
   </b-row>

 </div>
</template>

<script>
import Vue from "vue";
import {mapActions, mapMutations} from 'vuex';
import JsonCSV from "vue-json-csv";

Vue.component('downloadCsv', JsonCSV)

export default {
  name: "ContactSearch",
  
  data: () => ({
    contact: {
      name: null,
      surname: null,
      email: null,
      cellNumber: null,
      company: null,
      location: null,
      phone: null,
    },
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'company',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Location',
          key: 'location',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Landline',
          key: 'phone',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Mobile',
          key: 'cellNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    jsonData: [],
    fileName: `Contacts ${new Date().toLocaleDateString('en-ZA')}.csv`
  }),
  
  created() {
    this.searchResults()
    this.setBreadcrumb([
      {
        text: 'Contact'
      },
    ])
  },
  
  methods:{
    ...mapActions(['searchContactsRequest', 'createContact']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage', 'addSuccessMessage']),

    clearSearch() {
      this.contact = {
        name: null,
        surname: null,
        email: null,
        cellNumber: null,
      }
      this.searchResults()
    },

    searchResults() {
      this.tableData.isLoading = true
      
      let request = []
      
      if (this.contact.name != null){
        request.push({"key": "name", "value": this.contact.name})
      }
      if (this.contact.surname != null){
        request.push({"key": "surname", "value": this.contact.surname})
      }
      if (this.contact.email != null){
        request.push({"key": "email", "value": this.contact.email})
      }
      
      this.$store.commit('setContactSearchRequest', request)
      
      this.searchContactsRequest()
      .then((request) => {
        this.tableData.dataSource = request.data
        
        this.jsonData = this.tableData.dataSource.map( v => ({
          name: v.name,
          surname: v.surname,
          email: v.email,
          cellNumber: v.cellNumber,
          company: v.company,
          location: v.location,
          phone: v.phone,
        }))
        this.tableData.isLoading = false
      })
      .catch( () => {
        this.tableData.isLoading = false
      })
    },
    
    openContact(contact){
      this.$store.commit('setSelectedContact', contact)
      this.$router.push({path: '/contacts/contactView'})
    },
    
    addContact() {
      this.$router.push({path: '/contacts/createContact'})
    },
    
    downloadMessage() {
      if (this.jsonData.length === 0) {
        this.addWarningMessage('There is no data to be downloaded')
      } else {
        this.addSuccessMessage('Your content has been downloaded ')
      }
    },
  },
  computed:{
      rows() {
          return this.tableData.dataSource.length
      }
  }
}
</script>

<style scoped>


</style>